import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProfileService } from '../profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { bannerIMG } from 'src/app/shared/models/bannerIMG';
import { Product } from 'src/app/shared/models/product';
import { ShopService } from 'src/app/shop/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../account.service';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class CompanyOverviewComponent implements OnInit {
  products!: Product[];
  id: string = '';
  providerName: string = '';

  vendor = {
    name: this.providerName,
    avatar:
      'https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png',
    rating: 4.8,
    totalSales: 1234,
    location: 'Barcelona, España',
  };

  constructor(
    private shopService: ShopService,
    private activatedRoute: ActivatedRoute,
    public domSanitizer: DomSanitizer,
    public accoutService: AccountService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';

    this.getProductsProviderId();
    this.getFullName();
  }

  getProductsProviderId() {
    this.shopService.getProductsProviderId(this.id).subscribe({
      next: (response) => {
        this.products = response;
      },
      error: (error) => console.log(error),
    });
  }

  getFullName() {
    this.accoutService.getUserFullName(this.id).subscribe({
      next: (response) => {
        this.providerName = response.name;
        this.vendor.name = response.name;
      },
      error: (error) => console.log(error),
    });
  }
}
