import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ShopService } from '../shop/shop.service';
import { Product } from '../shared/models/product';
import { ShopParams } from '../shared/models/shopParams';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account/account.service';
import { MenuItem } from 'primeng/api';
import { shuffle } from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  imagesIndustrial: Product[] = [];
  imagesModa: Product[] = [];
  imagesSalud: Product[] = [];
  products: Product[] = [];
  topSellers: Product[] = [];
  @ViewChild('search') searchTerm?: ElementRef;
  shopParams: ShopParams;
  url: string;
  totalSlides = [1, 2, 4, 6, 8, 9]; // Total number of slides
  urlReg = environment.contentUrl + 'images/pages/12.jpg';
  items: MenuItem[] | undefined;
  categories: any;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px', // Large screens
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: '768px', // Medium screens
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '560px', // Small screens
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(
    private shopService: ShopService,
    private router: Router,
    public accountService: AccountService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.shopParams = new ShopParams();
    this.shopService.setShopParams(this.shopParams);

    this.url = environment.contentUrl + 'images/banner/';
    this.getProducts();
    this.shopParams = shopService.getShopParams();
    this.getCategories();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // Esperar a que se carguen las imágenes antes de inicializar el carrusel
  }

  ngOnDestroy() {}

  getCategories() {
    this.shopService.getProductCategory1().subscribe({
      next: (response) => {
        this.categories = response;

        this.items = response.map((categoria) => ({
          label: categoria.name,
          command: () => {
            this.onSearch(categoria.id);
          },
        }));
      },
      error: (error) => console.log(error),
    });
  }

  getTopSellerProducts() {
    const shuffledProducts = shuffle(this.products);

    // Seleccionar los primeros 8 productos mezclados
    this.topSellers = shuffledProducts.slice(0, 10);
  }

  getProducts() {
    this.shopService.getProducts().subscribe({
      next: (response) => {
        this.products = response.data;
        this.imagesModa = response.data.filter(
          (x) => x.category1 == 'Moda y Belleza ',
        );
        this.imagesSalud = response.data.filter((x) => x.category1 == 'Salud');
        this.imagesIndustrial = response.data.filter(
          (x) => x.category1 == 'Industrial',
        );
        this.getTopSellerProducts();
        console.log(response.data);
      },
      error: (error) => console.log(error),
    });
  }

  onSearch(id: number) {
    const params = this.shopService.getShopParams();
    params.categoryId = id;
    params.search = this.searchTerm?.nativeElement.value;
    params.pageNumber = 1;
    this.shopService.setShopParams(params);
    this.shopParams = params;
    this.getProducts();
    this.router.navigate(['/shop']);
  }

  onReset() {
    if (this.searchTerm) this.searchTerm.nativeElement.value = '';
    this.shopParams = new ShopParams();
    this.shopService.setShopParams(this.shopParams);
    this.getProducts();
  }
}
