<header class="tw-bg-white tw-shadow">
  <nav class="tw-container mx-auto tw-px-4 tw-py-4">
    <div class="tw-flex tw-items-center tw-justify-between">
      <a routerLink="/" class="tw-flex-shrink-0">
        <img src="/assets/images/logo.jpg" alt="Viammex Logo" class="tw-h-12" />
      </a>
      <div class="tw-mx-4 tw-flex-grow">
        <form class="tw-flex">
          <input
            class="tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-w-full tw-rounded-l-md tw-border tw-border-gray-300 tw-px-4 tw-py-2"
            type="search"
            (keyup.enter)="onSearch()"
            placeholder="Buscar productos..."
          />
          <button
            class="tw-hover:bg-blue-600 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-rounded-r-md tw-bg-blue-500 tw-px-4 tw-py-2 tw-text-white"
            type="submit"
            (click)="onSearch()"
          >
            <i class="pi pi-search"></i>
          </button>
        </form>
      </div>

      <button
        routerLink="/basket"
        class="tw-justify-end tw-hover:bg-gray-300 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-gray-500 position-relative tw-rounded-full tw-p-2"
      >
        <i class="fa fa-shopping-cart fa-2x" style="color: #00adef"> </i>
        <div
          *ngIf="basketService.basketSource$ | async as basket"
          class="cart-no"
        >
          {{ getCount(basket.items) }}
        </div>
      </button>

      <button
        class="tw-justify-end tw-hover:bg-gray-300 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-gray-500 tw-rounded-full tw-p-2"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      ></button>

      <ng-container *ngIf="(accountService.currentUser$ | async) === null">
        <div class="dropdown" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            ngbDropdownToggle
          >
            <i class="fa fa-user-circle-o fa-2x"></i>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            ngbDropdownMenu
            aria-labelledby="navbarDropdown"
          >
            <li>
              <a class="dropdown-item" routerLink="/account/login"
                ><mat-icon>login</mat-icon> Iniciar sesión</a
              >
            </li>
            <li>
              <a class="dropdown-item" routerLink="/account/register/client"
                ><mat-icon>person_add</mat-icon> Registro Cliente</a
              >
            </li>
            <li>
              <a class="dropdown-item" routerLink="/account/register/provider"
                ><mat-icon>sell</mat-icon> Registro Vendedor</a
              >
            </li>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngIf="accountService.currentUser$ | async as user">
        <div class="dropdown" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            ngbDropdownToggle
          >
            <strong>{{ user.fullName }}</strong>
          </a>
          <ul
            class="dropdown-menu"
            ngbDropdownMenu
            aria-labelledby="navbarDropdown"
          >
            <li>
              <a routerLink="/account/profile" class="dropdown-item"
                ><i class="fa fa-user me-3"></i> Perfil</a
              >
            </li>
            <li>
              <a routerLink="/basket" class="dropdown-item"
                ><i class="fa fa-shopping-cart me-3"></i> Ver Cesta</a
              >
            </li>
            <li>
              <a routerLink="/orders" class="dropdown-item"
                ><i class="fa fa-history me-3"></i> Ver Pedidos</a
              >
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a (click)="accountService.logout()" class="dropdown-item"
                ><i class="fa fa-sign-out me-3"></i> Cerrar Sesión</a
              >
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </nav>
</header>
