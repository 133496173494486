<div class="tw-container mx-auto tw-px-4 tw-py-4">
  <div class="vendor-info">
    <section class="tw-grid md:tw-grid-cols-2 sm:tw-grid-cols-1">
      <div class="tw-flex tw-align-items-center tw-gap-3 tw-p-3">
        <p-avatar
          [image]="vendor.avatar"
          size="xlarge"
          shape="circle"
        ></p-avatar>
        <div>
          <h1 class="text-2xl font-bold mb-2">{{ vendor.name }}</h1>
          <div class="flex align-items-center gap-2 mb-2">
            <i class="pi pi-star-fill text-yellow-500"></i>
            <span class="font-semibold">{{ vendor.rating }}</span>
            <span class="text-500">({{ vendor.totalSales }} ventas)</span>
          </div>
          <div class="flex align-items-center gap-2 text-500">
            <i class="pi pi-map-marker"></i>
            <span>{{ vendor.location }}</span>
          </div>
        </div>
      </div>

      <div class="tw-flex tw-justify-end tw-items-center tw-p-3">
        <p-button label="Contactar vendedor" icon="pi pi-send"></p-button>
      </div>
    </section>
  </div>

  <h2 class="text-2xl font-semibold my-4">Productos de {{ vendor.name }}</h2>

  <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-5 gap-4">
    <ng-container *ngFor="let product of products">
      <div class="product-card" routerLink="/shop/{{ product.id }}">
        <div class="card-normal">
          <img [src]="product.pictureUrl" [alt]="product.name" />
          <h3>{{ product.name }}</h3>
          <p>{{ product.price | currency }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
